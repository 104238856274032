<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>參數設定</b-breadcrumb-item>
            <b-breadcrumb-item active>SHOPLINE 設定</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card class="grid-margin" title="SHOPLINE 設定">
      <div class="row d-flex mb-4 mb-xl-2 justify-content-end">
        <b-button
          class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
          variant="primary"
          :to="{ name: 'ShoplineSettingForm' }"
          :disabled="!canEdit"
        >
          編輯設定
        </b-button>
      </div>

      <b-table striped hover responsive :items="settings" :fields="fields">
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import orgVariableApi from "@/apis/organization-variable";

export default {
  data() {
    return {
      settings: [],
      fields: [
        { key: "key", label: "key" },
        { key: "name", label: "名稱" },
        { key: "value", label: "value" },
      ],
      config: null,
      canEdit: false,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.fetchConfig();
      this.prepareSettings();
    },
    async fetchConfig() {
      try {
        const response = await orgVariableApi.getByKey({
          org_code: this.organization.id,
          key: "SHOPLINE-CONFIG",
        });

        this.config = JSON.parse(response.data.data.value || "{}");
        this.canEdit = true;
      } catch (error) {
        console.log(error);

        this.$swal.fire({
          type: "warning",
          title: "SHOPLINE 設定尚未啟用",
        });

        throw error;
      }
    },
    prepareSettings() {
      const token = this.config?.credentials?.token || "";

      this.settings = [
        {
          key: "credentials.token",
          name: "Shopline API Token",
          value: this.$options.filters.hiddenString(token, 50),
        },
      ];
    },
  },
};
</script>
